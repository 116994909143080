class Accordion {
	constructor() {
		this.accordionElem = document.querySelector(".js-accordion");
	}
	init() {
		let panels = this.accordionElem.querySelectorAll(".accordion-panel");
		panels.forEach((panel) => {
			panel.addEventListener("click", this.handlePanelClick.bind(this), false);
		});
	}

	//when panel is clicked, handlePanelClick is called.
	handlePanelClick(event) {
		if (event.currentTarget.classList.contains("panel--active")) {
			this.hidePanel(event.currentTarget);
		} else {
			this.showPanel(event.currentTarget);
		}
	}
	//Hide currentPanel and show new panel.
	showPanel(panel) {
		//Hide current one. First time it will be null.
		let expandedPanel = this.accordionElem.querySelector(".panel--active");
		if (expandedPanel) {
			expandedPanel.classList.remove("panel--active");
		}
		//Show new one
		panel.classList.add("panel--active");
	}
	//Hide panel clicked if opened
	hidePanel(panel) {
		panel.classList.remove("panel--active");
	}
}
export default Accordion;

if (document.querySelector(".js-accordion")) {
	const accordion = new Accordion();
	accordion.init();
}
