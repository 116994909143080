class Close {
  constructor() {
    this.closeElem = document.querySelector('.js-close');
  }
  init() {
    this.closeElem.addEventListener('click', this.handleElementClick.bind(this), false);
  }

  //when element is clicked, handleElementClick is called.
  handleElementClick(event) {
    let elementToClose = event.currentTarget.dataset.close;
    this.closeElement(elementToClose);
  }

  //Hide element with class
  closeElement(classSelected) {
    document.querySelector(`.${classSelected}`).style.display = 'none';
  }
}
export default Close;

if (document.querySelector('.js-close')) {
  const close = new Close();
  close.init();
}
